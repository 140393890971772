import React, { useState, useEffect } from 'react';
import ReactDOMServer from 'react-dom/server';
import axios from 'axios';
import "../CompanyProfile.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faBullhorn, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import closeIcon from "../images/close1.png"
import goldBadge from '../images/gold_badge.png'
import basicBadge from '../images/basic_badge.png'
import html2pdf from 'html2pdf.js/dist/html2pdf.min';
import CompanyProfilePdf from './CompanyProfilePdf';
import LoadingSpinner from './LoadingSpinner';

import { useTranslation } from "react-i18next";

const ExpandButton = ({ expanded, handleExpand }) => {
  return (
    <div className='plus-sign-btn' onClick={(e) => handleExpand(e.currentTarget)}>
      {expanded ? <FontAwesomeIcon icon={faMinus} className='plus-sign-icon'/> : <FontAwesomeIcon icon={faPlus} className='plus-sign-icon'/>}
    </div>
  )
}

const CompanyProfile = (props) => {
  const { t } = useTranslation();
  const toggleSubText = (event) => {
    const parentDiv = event.target.parentElement.parentElement;
    const childrenArray = Array.from(parentDiv.children);
    const policyItems = childrenArray.filter((child) =>
      child.classList.value == 'company-policy-item-sub-text'
    );
    if (event.target.textContent[0] == '+'){
      setExpanded(true);
      policyItems.forEach((policyItem) => {
        policyItem.style.display = 'block';
      })
      event.target.textContent = '-' + event.target.textContent.substr(1);
    } else {
      setExpanded(false);
      policyItems.forEach((policyItem) => {
        policyItem.style.display = 'none';
      })
      event.target.textContent = '+' + event.target.textContent.substr(1);
    }
  };

  const expandAll = () => {
    const policyItems = document.querySelectorAll('.company-policy-item-sub-text');
    policyItems.forEach((policyItem) => {
      if (expanded) {
        const subHeader = policyItem.parentElement.children[0].children[0];
        subHeader.textContent = '+' + subHeader.textContent.substr(1);
        policyItem.style.display = 'none';
        setExpanded(false);
      } else {
        const subHeader = policyItem.parentElement.children[0].children[0];
        subHeader.textContent = '-' + subHeader.textContent.substr(1);
        policyItem.style.display = 'block';
        setExpanded(true);
      }
    })
  };

  const checkLocation = (val) => {
    const locations = [
      { value: -1, label: "Asia Overall" },
      { value: 0, label: "Mainland China" },
      { value: 1, label: "Hong Kong SAR" },
      { value: 9, label: "Taiwan" },
      { value: 2, label: "India" },
      { value: 3, label: "Indonesia" },
      { value: 4, label: "Japan" },
      { value: 5, label: "Korea" },
      { value: 6, label: "Malaysia" },
      { value: 7, label: "the Philippines" },
      { value: 8, label: "Singapore" },
      { value: 10, label: "Thailand" },
      { value: 11, label: "Vietnam" },
    ];
  
    const locationObj = locations.find(location => location.value === val);
  
    return locationObj ? locationObj.label : "Unknown Location";
  } 

  const calculatePercentage = (numerator, denominator) => {
    if (numerator === -1 || denominator === -1) {
      return 'N/A';
    }    
    const percentage = (numerator / denominator * 100).toFixed(1);
    if (isNaN(percentage)) {
      return 'N/A';
    } else {
      return `${percentage}%`;
    }
  };

  const checkMinusOne = (val) => {
    return val === -1 ? 'N/A' : `${val}%`;
  }

  const expandOne = (button) => {
    const section = button.closest('.company-policy');
    const subTextList = section.querySelectorAll('.company-policy-item-sub-text');
    console.log(subTextList)
    let isCurrentlyExpanded = false;
    subTextList.forEach((subText) => {
      if (subText.style.display === 'block') {
        isCurrentlyExpanded = true;
      }
    });
    subTextList.forEach((subText) => {
      subText.style.display = isCurrentlyExpanded ? 'none' : 'block';
    });
    
    const buttonState = section.querySelector('.plus-sign-btn');
    buttonState.setAttribute('data-expanded', !isCurrentlyExpanded);
    setExpanded(!isCurrentlyExpanded); // If you need global state
  }

  const {handleBackArrowClick} = props;
  const [companyData, setCompanyData] = useState();
  const [loading, setLoading] = useState(true);
  const [expanded, setExpanded] = useState(false);
  const [gold, setGold] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    };

    const requestData = {
      id: props.companyId,
    }

    axios.post('https://wwi-questionnaire-v2.azurewebsites.net/api/getCompanyData', requestData, config)
      .then(response => {
        console.log(response.data);
        if (response?.data?.data?.membership == 'GOLD') {
          setGold(true);
        }
        if (response?.data?.data?.membership !== 'GOLD' && response?.data?.data?.membership !== 'BASIC') {
          setShowModal(true);
        }
        setCompanyData(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error making POST request:', error);
        setLoading(false);
      });
  }, [props.companyId]);

  const handleDownloadClick = () => {
    const printContent = <CompanyProfilePdf companyData={companyData} gold={gold} />;
    const printHtml = ReactDOMServer.renderToString(printContent);

    const container = document.createElement('div');
    container.innerHTML = printHtml;
  
    const options = {
      margin: [10, 0, 10, 0],
      filename: `${companyData?.data?.id}.pdf`,
      image: { type: 'jpeg', quality: 1 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    };    
    html2pdf(container, options);
  };

  return (loading ? <LoadingSpinner/> : 
      <div className="company-profile">
        {showModal && 
          <div className='launching-modal-profile absolute flex justify-between'>
            <div className='flex justify-center items-center bg-[#760326]'>
              <FontAwesomeIcon 
                icon={faBullhorn} 
                className='h-[2rem] w-[2rem] m-2 text-white cursor-pointer' 
                onClick={handleBackArrowClick} 
              />
            </div>
            <div className='text-xs flex-grow text-left my-auto sm:text-base font-semibold text-[#760326] m-2'>
              Below inputs are collated by WWi based on the entity's public disclosure.
            </div>
            <div className='my-auto cursor-pointer' onClick={() => {setShowModal(false)}}>
              <i className="fas fa-times text-[#760326] text-2xl m-2"></i>
            </div>
          </div>
        }
        <div className='header-image-container'>
          <div className="image">
            <p className="main-heading">
              {t("profile.companyProfile")}
            </p>
          </div>
        </div>
        <div className='company-profile-content'>
          <div className='flex justify-between p-2 mt-2'>
            <FontAwesomeIcon icon={faArrowLeft} className='back-arrow-icon' onClick={handleBackArrowClick} />
            <div className='red-button my-auto' onClick={handleDownloadClick}>
              <div className='red-button-text'>{t("profile.download")}</div>
            </div>
          </div>
          <div className='company-profile-header-section'>
            <div className='company-header-section1'>
              <div className='company-title'>{companyData?.data?.data?.['0.1']}</div>
              {/* <img src={VerifiedIcon} alt='back' className='verified-icon'></img> */}
              <div className='company-certification'>
                {
                  (() => {
                    switch (companyData?.data?.membership) {
                      case "GOLD":
                        return <img src={goldBadge} alt="gold" className='w-[5rem]'/>
                      case "BASIC":
                        return <img src={basicBadge} alt="basic" className='w-[5rem]'/>
                      default:
                        return <div>IN-SCOPE</div>
                    }
                  })()
                }
              </div>
            </div>
            <div className='company-header-section2'>
              <div className="company-header-subsection1-2">
                <span>{t("profile.headquarterAsia")}</span>&nbsp;&nbsp;<span style={{ fontWeight: "400" }}>{companyData?.data?.data?.['0.7.b']}</span> <div className="linespacing" />
                <span>{t("profile.industry")}</span>&nbsp;&nbsp;<span style={{ fontWeight: "400" }}>{companyData?.data?.data?.['0.5'][0]}</span>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;<span>{t("profile.subindustry")}</span>&nbsp;&nbsp;&nbsp;<span style={{ fontWeight: "400" }}>{companyData?.data?.data?.['0.5'][1]}</span>
              </div>
              <div className='company-header-subsection2-2'>
                <div className='company-header-subsubsection1-2-2'>
                  {/* <div className='reporting-year'>
                    Reporting Year: 
                  </div>
                  <select className='year-select'>
                    {Array.from({ length: 30 }, (_, index) => new Date().getFullYear() - index).map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select> */}
                </div>
                <div className='company-header-subsubsection2-2-2'>
                  {/* <div className='red-button'>
                      <p className='red-button-text'>Bookmark</p>
                  </div> */}
                  {/* <div className='red-button' onClick={handleDownloadClick}>
                    <p className='red-button-text'>{t("profile.download")}</p>
                  </div> */}
                </div>
              </div>
            </div>
            <div className='profile-line'/>
            <table className='company-header-section3'>
              <tbody className='md:w-9/12'>
                <tr className="info-titles">
                  <th className="nowrap pr-2">{t("profile.aboutEntity")}</th>
                  <td className="info-details">{companyData?.data?.data?.['0.2']}</td>
                </tr>
                <tr className="info-titles">
                  <th className="nowrap pr-2"><div className="info-titles-line" /></th>
                  <td className="info-details"><div className="info-details-line" /></td>
                </tr>
                <tr className="info-titles">
                  <th className="nowrap pr-2">{t("profile.natureEntity")}</th>
                  <td className="info-details">
                    {companyData?.data?.data?.['0.4']} | {companyData?.data?.data?.['0.3.b'] || "N/A"}
                  </td>
                </tr>
                <tr className='info-titles'>
                  <th className='nowrap pr-2'><div className='info-titles-line'/></th>
                  <td className='info-details'><div className='info-details-line'/></td>
                </tr>
                <tr className='info-titles'>
                  <th className='nowrap pr-2'>{t("profile.reportingPeriod")}</th>
                  <td className='info-details'>{companyData?.data?.data?.['0.6.1']} – {companyData?.data?.data?.['0.6.2']}</td>
                </tr>
                <tr className='info-titles'>
                  <th className='nowrap pr-2'><div className='info-titles-line'/></th>
                  <td className='info-details'><div className='info-details-line'/></td>
                </tr>
                <tr className="info-titles">
                  <th className="nowrap pr-2">{t("profile.employeeProfile")}</th>
                  <td className="info-details">
                    <span style={{ fontWeight: "700" }}>{t("profile.global")}:</span>{' '}
                    {companyData?.data?.data?.['0.8.1'] != '-1'
                      ? `${companyData?.data?.data?.['0.8.1']} ${t("profile.employees")}`
                      : t("profile.notDisclosed")}{' '}
                    |{' '}
                    <span style={{ fontWeight: "700" }}>{t("profile.asia")}:</span>{' '}
                    {companyData?.data?.data?.['0.8.2'] != '-1'
                      ? `${companyData?.data?.data?.['0.8.2']} ${t("profile.employees")}`
                      : t("profile.notDisclosed")}
                  </td>
                </tr>
                <tr className='info-titles'>
                  <th className='nowrap pr-2'><div className='info-titles-line'/></th>
                  <td className='info-details'><div className='info-details-line'/></td>
                </tr>
                <tr className='info-titles'>
                  <th className='nowrap pr-2'>{t("profile.publicDisclosures")}</th>
                  {gold && (companyData?.data?.data?.['19.1'] == 'Public disclosures are available' ? (
                    <td className='info-details'>{companyData?.data?.data?.['19.1.a']?.map((item) => (
                      <>
                        <a href={item?.['19.1.a.2']}>{item?.['19.1.a.1']}</a>
                        <span>&nbsp;|&nbsp;</span>
                      </>
                    ))}</td>                    
                  ):(
                    <td className='info-details'>N/A</td>
                  ))}
                  {!gold && (companyData?.data?.data?.['18.1'] == 'Public disclosures are available' ? (
                    <td className='info-details'>{companyData?.data?.data?.['18.1.a']?.map((item) => (
                      <>
                        <a href={item?.['18.1.a.2']}>{item?.['18.1.a.1']}</a>
                        <span>&nbsp;|&nbsp;</span>
                      </>
                    ))}</td>                    
                  ):(
                    <td className='info-details'>N/A</td>
                  ))}
                </tr>
                <tr className='info-titles'>
                  <th className='nowrap pr-2'><div className='info-titles-line'/></th>
                  <td className='info-details'><div className='info-details-line'/></td>
                </tr>
                <tr className='info-titles'>
                  <th className='nowrap pr-2'>{t("profile.publicPolicies")}</th>
                  {gold && (companyData?.data?.data?.['19.2'] == 'Public disclosures are available' ? (
                    <td className='info-details'>{companyData?.data?.data?.['19.2.a']?.map((item) => (
                      <>
                        <a href={item?.['19.2.a.2']}>{item?.['19.2.a.1']}</a>
                        <span>&nbsp;|&nbsp;</span>
                      </>
                    ))}</td>                    
                  ):(
                    <td className='info-details'>N/A</td>
                  ))}
                  {!gold && (companyData?.data?.data?.['18.2'] == 'Public disclosures are available' ? (
                    <td className='info-details'>{companyData?.data?.data?.['18.2.a']?.map((item) => (
                      <>
                        <a href={item?.['18.2.a.2']}>{item?.['18.2.a.1']}</a>
                        <span>&nbsp;|&nbsp;</span>
                      </>
                    ))}</td>                    
                  ):(
                    <td className='info-details'>N/A</td>
                  ))}
                </tr>
                <tr className='info-titles'>
                  <th className='nowrap pr-2'><div className='info-titles-line'/></th>
                  <td className='info-details'><div className='info-details-line'/></td>
                </tr>
              </tbody>
              {/* <div className='info-certificate w-3/12'>
                <span className='media-reports'>Media Reports (121)&nbsp;&nbsp; <img style={{height:'30px', width:'30px'}} src={CubeIcon} alt='search' className='cube-icon'/></span>
                <img style={{height:'220px', width:'315px'}} src={Certificate} alt='search' className='certificate'/>
              </div> */}
            </table>
            <div className='profile-line'/>
            <div className='expand-all-div'>
              <div className='red-button-thicker'>
                <p className='red-button-thicker-text' onClick={() => expandAll()}>{expanded ? "Collapse All" : "Expand All"}</p>
              </div>
            </div>

            <div className='company-policy'>
              <div className='company-policy-header'>
                <div className='company-policy-header-line1'>
                  <div className='company-policy-title'>{t("profile.policyOperations")}</div>
                  <ExpandButton expanded={expanded} handleExpand={expandOne} />
                </div>
                <div className='company-policy-text'>{t("profile.keyWomanTopicsDesc")}</div>
              </div>
              <div className='company-policy-section'>
                <div className='company-policy-items'>
                  <div className='company-policy-item-text' onClick={(event) => toggleSubText(event)}>
                    + {t("profile.managementResponsibility")}
                  </div>
                </div>
                <div className='company-policy-item-sub-text'>
                  {t("profile.seniorManagementDesc")}<br/><br/>
                  <span className="company-policy-item-ans-line">
                    {companyData?.data?.data?.['1.1']} {(companyData?.data?.data?.['1.1.a'] && companyData?.data?.data?.['1.1.a']?.[0] != 'N') ? `- ${companyData?.data?.data?.['1.1.a']}` : ''}
                  </span><br/>
                  {gold && <span className="company-policy-item-ans-line">{companyData?.data?.data?.['1.1.b']}</span>}
                  <br/><br/>
                </div>
              </div>

              <div className='company-policy-section'>
                <div className='company-policy-items'>
                  <div className='company-policy-item-text' onClick={(event) => toggleSubText(event)}>
                    + {t("profile.antiSexualHarassment")}
                  </div>
                </div>
                <div className='company-policy-item-sub-text'>
                  <span>{t("profile.harassmentPolicy")}</span><br/><br/>
                  <span className="company-policy-item-ans-line">{companyData?.data?.data?.['2.1']}</span><br/>
                  {gold && <span className="company-policy-item-ans-line">{companyData?.data?.data?.['2.1.a']}</span>}
                  {gold && <span className="company-policy-item-ans-line">{companyData?.data?.data?.['2.1.b']}</span>}
                  {gold && <span className="company-policy-item-ans-line">{companyData?.data?.data?.['2.1.c']}</span>}
                  <br/><br/>
                </div>
                <div className='company-policy-item-sub-text'>
                  {t("profile.reportingChannels")}<br/><br/>
                  <span className="company-policy-item-ans-line">{companyData?.data?.data?.['2.2']}</span><br/>
                  {gold && <span className="company-policy-item-ans-line">{companyData?.data?.data?.['2.2.a']}</span>}
                  {gold && <span className="company-policy-item-ans-line">{companyData?.data?.data?.['2.2.b']}</span>}
                  <br/><br/>
                </div>
                <div className='company-policy-item-sub-text'>
                  {t("profile.independentMechanism")}<br/><br/>
                  <span className="company-policy-item-ans-line">{companyData?.data?.data?.['2.3']}</span>
                  {gold && <span className="company-policy-item-ans-line">{companyData?.data?.data?.['2.3.a']}</span>}
                  {gold && <span className="company-policy-item-ans-line">{companyData?.data?.data?.['2.3.b']}</span>}
                  <br/><br/>
                </div>
              </div>

              <div className='company-policy-section'>
                <div className='company-policy-items'>
                  <div className='company-policy-item-text' onClick={(event) => toggleSubText(event)}>
                    + {t("profile.nonDiscrimination")}
                  </div>
                </div>
                <div className='company-policy-item-sub-text'>
                  <span>{t("profile.nonDiscrimination31")}</span><br/><br/>
                  <span className="company-policy-item-ans-line">{companyData?.data?.data?.['3.1']}</span><br/>
                  {gold && <span className="company-policy-item-ans-line">{companyData?.data?.data?.['3.1.a']}</span>}
                  {gold && <span className="company-policy-item-ans-line">{companyData?.data?.data?.['3.1.b']}</span>}
                  {gold && <span className="company-policy-item-ans-line">{companyData?.data?.data?.['3.1.c']}</span>}
                  <br/><br/>
                </div>
                <div className='company-policy-item-sub-text'>
                  {t("profile.nonDiscrimination32")}<br/><br/>
                  <span className="company-policy-item-ans-line">{companyData?.data?.data?.['3.2']}</span><br/>
                  {gold && <span className="company-policy-item-ans-line">{companyData?.data?.data?.['3.2.a']}</span>}
                  {gold && <span className="company-policy-item-ans-line">{companyData?.data?.data?.['3.2.b']}</span>}
                  <br/><br/>
                </div>
                <div className='company-policy-item-sub-text'>
                  {t("profile.nonDiscrimination33")}<br/><br/>
                  <span className="company-policy-item-ans-line">{companyData?.data?.data?.['3.3']}</span><br/>
                  {gold && <span className="company-policy-item-ans-line">{companyData?.data?.data?.['3.3.a']}</span>}
                  {gold && <span className="company-policy-item-ans-line">{companyData?.data?.data?.['3.3.b']}</span>}
                  <br/><br/>
                </div>
              </div>

              <div className='company-policy-section'>
                <div className='company-policy-items'>
                  <div className='company-policy-item-text' onClick={(event) => toggleSubText(event)}>
                    + {t("profile.parentalLeave")}
                  </div>
                </div>
                <div className='company-policy-item-sub-text'>
                  <span>{t("profile.parentalLeave41")}</span><br/><br/>
                  {companyData?.data?.data?.['4.1'].toString().includes('Yes') ? (
                    <>
                      <span className="company-policy-item-ans-line">{companyData?.data?.data?.['4.1']}</span><br/>
                      {!gold && companyData?.data?.data?.['4.1.a']?.map((item) => (
                        <>
                          <span className="company-policy-item-ans-line">{checkLocation(item?.['4.1.a.loc'])} - {t("profile.parentalLeaveFullPaidMaternity")}: {item?.['4.1.a.1'] == -1 ? t("profile.daysNotDisclosed") : `${item?.['4.1.a.1']} days`}, {companyData?.data?.data?.['4.1.a']?.[0]?.['4.1.a.3']}</span><br/>
                          <span className="company-policy-item-ans-line">{checkLocation(item?.['4.1.a.loc'])} - {t("profile.parentalLeaveFullPaidPaternity")}: {item?.['4.1.a.2'] == -1 ? t("profile.daysNotDisclosed") : `${item?.['4.1.a.2']} days`}, {companyData?.data?.data?.['4.1.a']?.[0]?.['4.1.a.3']}</span><br/>
                          <span className="company-policy-item-ans-line">{checkLocation(item?.['4.1.a.loc'])} - {t("profile.parentalLeavePartPaidMaternity")}: % {item?.['4.1.a.7'] == -1 ? t("profile.payNotDisclosed") : `${item?.['4.1.a.7']} Pay`}, {item?.['4.1.a.4'] == -1 ? t("profile.daysNotDisclosed") : `${item?.['4.1.a.4']} Pay`}, {companyData?.data?.data?.['4.1.a']?.[0]?.['4.1.a.3']}</span><br/>
                          <span className="company-policy-item-ans-line">{checkLocation(item?.['4.1.a.loc'])} - {t("profile.parentalLeavePartPaidPaternity")}: % {item?.['4.1.a.8'] == -1 ? t("profile.payNotDisclosed") : `${item?.['4.1.a.8']} Pay`}, {item?.['4.1.a.5'] == -1 ? t("profile.daysNotDisclosed") : `${item?.['4.1.a.5']} Pay`}, {companyData?.data?.data?.['4.1.a']?.[0]?.['4.1.a.3']}</span><br/>
                          <span className="company-policy-item-ans-line">{checkLocation(item?.['4.1.a.loc'])} - {t("profile.parentalLeaveNoPaidMaternity")}{item?.['4.1.a.9'] == -1 ? t("profile.notDisclosed") : `${item?.['4.1.a.9']}`}, {companyData?.data?.data?.['4.1.a']?.[0]?.['4.1.a.3']}</span><br/>
                          <span className="company-policy-item-ans-line">{checkLocation(item?.['4.1.a.loc'])} - {t("profile.parentalLeaveNoPaidPaternity")}{item?.['4.1.a.10'] == -1 ? t("profile.notDisclosed") : `${item?.['4.1.a.10']}`}, {companyData?.data?.data?.['4.1.a']?.[0]?.['4.1.a.3']}</span>
                        </>
                      ))}
                      {gold && companyData?.data?.data?.['4.1.a']?.map((item) => (
                        <>
                          <span className="company-policy-item-ans-line">{checkLocation(item?.['4.1.a.loc'])} - {t("profile.parentalLeaveFullPaidMaternity")}{item?.['4.1.a.1'] == -1 ? t("profile.daysNotDisclosed") : `${item?.['4.1.a.1']} days`}</span><br/>
                          <span className="company-policy-item-ans-line">{checkLocation(item?.['4.1.a.loc'])} - {t("profile.parentalLeaveFullPaidPaternity")}: {item?.['4.1.a.2'] == -1 ? t("profile.daysNotDisclosed") : `${item?.['4.1.a.2']} days`}</span><br/>
                          <span className="company-policy-item-ans-line">{checkLocation(item?.['4.1.a.loc'])} - {t("profile.parentalLeavePartPaidMaternity")}: % {item?.['4.1.a.7'] == -1 ? t("profile.payNotDisclosed") : `${item?.['4.1.a.7']} Pay`}, {item?.['4.1.a.4'] == -1 ? t("profile.daysNotDisclosed") : `${item?.['4.1.a.4']} Pay`}</span><br/>
                          <span className="company-policy-item-ans-line">{checkLocation(item?.['4.1.a.loc'])} - {t("profile.parentalLeavePartPaidPaternity")}: % {item?.['4.1.a.8'] == -1 ? t("profile.payNotDisclosed") : `${item?.['4.1.a.8']} Pay`}, {item?.['4.1.a.5'] == -1 ? t("profile.daysNotDisclosed") : `${item?.['4.1.a.5']} Pay`}</span><br/>
                          <span className="company-policy-item-ans-line">{checkLocation(item?.['4.1.a.loc'])} - {t("profile.parentalLeaveNoPaidMaternity")}{item?.['4.1.a.9'] == -1 ? t("profile.notDisclosed") : `${item?.['4.1.a.9']}`}</span><br/>
                          <span className="company-policy-item-ans-line">{checkLocation(item?.['4.1.a.loc'])} - {t("profile.parentalLeaveNoPaidPaternity")}{item?.['4.1.a.10'] == -1 ? t("profile.notDisclosed") : `${item?.['4.1.a.10']}`}</span>
                        </>
                      ))}
                    </>
                  ) : (
                    <span className="company-policy-item-ans-line">{companyData?.data?.data?.['4.1']}<br/></span>
                  )}
                  <br/><br/>
                </div>
              </div>

              <div className='company-policy-section'>
                <div className='company-policy-items'>
                  <div className='company-policy-item-text' onClick={(event) => toggleSubText(event)}>+ {t("profile.returnToWork")}</div>
                </div>
                <div className='company-policy-item-sub-text'>
                  <span>{t("profile.returnRate")}</span><br/><br/>
                  <span className="company-policy-item-ans-line">{companyData?.data?.data?.['5.1']}</span>
                  <br/>
                  <span className="company-policy-item-ans-line">{calculatePercentage(companyData?.data?.data?.['5.1.a'], 100)}</span>
                  <br/><br/>
                </div>
                {gold && 
                  <>
                    <div className='company-policy-item-sub-text'>
                      {t("profile.recruitmentMeasures")}<br/><br/>
                      <span className="company-policy-item-ans-line">{companyData?.data?.data?.['5.2']}</span>
                      <br/><br/>
                      <span className="company-policy-item-ans-line">{companyData?.data?.data?.['5.2.a']}</span>
                      <span className="company-policy-item-ans-line">{companyData?.data?.data?.['5.2.b']}</span>
                      <br/><br/>
                    </div>
                  </>
                }
              </div>

              <div className='company-policy-section'>
                <div className='company-policy-items'>
                  <div className='company-policy-item-text' onClick={(event) => toggleSubText(event)}>+ {t("profile.familyCare")}</div>
                </div>
                <div className='company-policy-item-sub-text'>
                  {t("profile.familySupport")}<br/><br/>
                  <span className="company-policy-item-ans-line">{companyData?.data?.data?.['6.1']}</span><br/>
                  {companyData?.data?.data?.['6.1.a'] && (
                    <>
                      {companyData.data.data['6.1.a'].map((item, index) => (
                        <span key={index} className="company-policy-item-ans-line">- {item}<br/></span>
                      ))}
                    </>
                  )}
                  <span className="company-policy-item-ans-line">{companyData?.data?.data?.['6.1.a.1']}</span>
                  <br/><br/>
                </div>
              </div>

              <div className='company-policy-section'>
                <div className='company-policy-items'>
                  <div className='company-policy-item-text' onClick={(event) => toggleSubText(event)}>+ {t("profile.pay")}</div>
                </div>
                <div className='company-policy-item-sub-text'>
                  {t("profile.payMetricsPolicy")}<br/><br/>
                  <span className="company-policy-item-ans-line">{companyData?.data?.data?.['7.1']}</span><br/>
                  {gold ? 
                    <span className="company-policy-item-ans-line">{companyData?.data?.data?.['7.1.a']}</span>
                  :
                    <span className="company-policy-item-ans-line">{companyData?.data?.data?.['7.1.a'] ? `Percentage(%) of earnings for female employees compared to male employees: ${companyData?.data?.data?.['7.1.a']}` : ''}</span>
                  }
                  <br/><br/>
                </div>
                {gold && 
                  <>
                    <div className='company-policy-item-sub-text'>
                      {t("profile.payMetricsTrack")}<br/><br/>
                      <span className="company-policy-item-ans-line">{companyData?.data?.data?.['7.2']}</span><br/>
                      <span className="company-policy-item-ans-line">{checkLocation(companyData?.data?.data?.['7.2.a']?.[0]?.['7.2.a.loc'])} - {companyData?.data?.data?.['7.2.a']?.[0]?.['7.2.a.1']} </span>
                      {companyData?.data?.data?.['7.2.a']?.[0]?.['7.2.a.2'] && 
                        <span className="company-policy-item-ans-line">{checkLocation(companyData?.data?.data?.['7.2.a']?.[0]?.['7.2.a.loc'])} - {companyData?.data?.data?.['7.2.a']?.[0]?.['7.2.a.2']} </span> 
                      }
                      {companyData?.data?.data?.['7.2.a']?.[0]?.['7.2.a.3'] && 
                        <span className="company-policy-item-ans-line">{checkLocation(companyData?.data?.data?.['7.2.a']?.[0]?.['7.2.a.loc'])} - {companyData?.data?.data?.['7.2.a']?.[0]?.['7.2.a.3']} </span> 
                      }
                      {companyData?.data?.data?.['7.2.b']?.[0]?.['7.2.b.2'] && 
                        <span className="company-policy-item-ans-line">{checkLocation(companyData?.data?.data?.['7.2.b']?.[0]?.['7.2.b.loc'])} - Percentage(%) of earnings for female employees compared to male employees: {companyData?.data?.data?.['7.2.b']?.[0]?.['7.2.b.2']}% </span> 
                      }
                      {companyData?.data?.data?.['7.2.b']?.[0]?.['7.2.b.3'] && 
                        <span className="company-policy-item-ans-line">Remarks: {companyData?.data?.data?.['7.2.b']?.[0]?.['7.2.b.3']} </span> 
                      }
                      {companyData?.data?.data?.['7.2.b']?.[0]?.['7.2.b.4'] && companyData?.data?.data?.['7.2.b']?.[0]?.['7.2.b.1'] == "We have the gender pay gap data but do not wish to provide it at the moment" &&
                        <span className="company-policy-item-ans-line">{companyData?.data?.data?.['7.2.b']?.[0]?.['7.2.b.4']} </span> 
                      }
                      {companyData?.data?.data?.['7.2.c']?.[0]?.['7.2.c.loc'] && companyData?.data?.data?.['7.2.c']?.[0]?.['7.2.c.1'] && companyData?.data?.data?.['7.2.c']?.[0]?.['7.2.c.2'] && 
                        <span className="company-policy-item-ans-line">{checkLocation(companyData?.data?.data?.['7.2.c']?.[0]?.['7.2.c.loc'])} - {companyData?.data?.data?.['7.2.c']?.[0]?.['7.2.c.1']} <br/>{companyData?.data?.data?.['7.2.c']?.[0]?.['7.2.c.2']} </span> 
                      }
                      {companyData?.data?.data?.['7.2.d'] && companyData?.data?.data?.['7.2'] == "Yes – We track other gender pay metrics" &&
                        <span className="company-policy-item-ans-line">{companyData?.data?.data?.['7.2.d']} </span> 
                      }
                      {companyData?.data?.data?.['7.2.e'] && companyData?.data?.data?.['7.2'] == "No – We currently do not track such information" &&
                        <span className="company-policy-item-ans-line">{companyData?.data?.data?.['7.2.e']} </span> 
                      }
                      <br/><br/>
                    </div>
                    <div className='company-policy-item-sub-text'>
                      {t("profile.payRectification")}<br/>
                      <span className="company-policy-item-ans-line">{companyData?.data?.data?.['7.3']}</span><br/>
                      {companyData?.data?.data?.['7.3'] == "Yes" &&
                        <span className="company-policy-item-ans-line">{companyData?.data?.data?.['7.3.a']}<br/></span>
                      }

                      {companyData?.data?.data?.['7.3'] == "No – No rectification action is in place currently" &&
                        <span className="company-policy-item-ans-line">{companyData?.data?.data?.['7.3.b']}</span>
                      }
                      <br/><br/>
                    </div>
                  </>
                }
              </div>
            </div>

            <div className='company-policy'>
              <div className='company-policy-header'>
                <div className='company-policy-header-line1'>
                  <div className='company-policy-title'>{t("profile.peopleAndCulture")}</div>
                  <ExpandButton expanded={expanded} handleExpand={expandOne} />
                </div>
                <div className='company-policy-text'>{t("profile.diversityMonitoring")}</div>
              </div>
              <div className='company-policy-section'>
                <div className='company-policy-items'>
                  <div className='company-policy-item-text' onClick={(event) => toggleSubText(event)}>
                    + {t("profile.workforceGender")}
                  </div>
                </div>
                <div className='company-policy-item-sub-text'>
                  {t("profile.womenWorkforceTracking")} <br/><br/>
                  {companyData?.data?.data?.['8.1']?.map((item) => (
                    <>
                      <span className="company-policy-item-ans-line">
                        {checkLocation(item?.['8.1.loc'])}: {calculatePercentage(item?.['8.1.2'], item?.['8.1.1'] + item?.['8.1.2'])} 
                      </span> 
                      {(item?.['8.1.4'] && item?.['8.1.3']) ? (
                        <span>
                          ({t("profile.previousYear")}: {calculatePercentage(item?.['8.1.4'], item?.['8.1.3'] + item?.['8.1.4'])}
                          , {t("profile.yearBeforePrevious")}: {calculatePercentage(item?.['8.1.6'], item?.['8.1.5'] + item?.['8.1.6'])}
                          )
                        </span>
                      ) : ''}
                      <br/>
                    </>
                  ))}
                  <br/>
                </div>
                <div className='company-policy-item-sub-text'>
                  {t("profile.womenByLevel")} <br/><br/>
                  {!gold && 
                    <span className="company-policy-item-ans-line">{companyData?.data?.data?.['8.2']}<br/>{companyData?.data?.data?.['8.2.a']}<br/>{companyData?.data?.data?.['8.2.c']}<br/><br/></span>
                  }
                  {gold && 
                    <>
                      <span className="company-policy-item-ans-line">
                        {checkLocation(companyData?.data?.data?.['8.2']?.[0]?.['8.2.loc'])}
                        - Senior management (including C-suite and other senior management): &nbsp;
                        {calculatePercentage(companyData?.data?.data?.['8.2']?.[0]?.['8.2.2'], (companyData?.data?.data?.['8.2']?.[0]?.['8.2.1'] + companyData?.data?.data?.['8.2']?.[0]?.['8.2.2']))}
                        ({t("profile.previousYear")}: {calculatePercentage(companyData?.data?.data?.['8.2']?.[0]?.['8.2.4'], (companyData?.data?.data?.['8.2']?.[0]?.['8.2.3'] + companyData?.data?.data?.['8.2']?.[0]?.['8.2.4']))}
                        , {t("profile.yearBeforePrevious")}: {calculatePercentage(companyData?.data?.data?.['8.2']?.[0]?.['8.2.6'], (companyData?.data?.data?.['8.2']?.[0]?.['8.2.5'] + companyData?.data?.data?.['8.2']?.[0]?.['8.2.6']))} )
                      </span>
                      <br/>
                      <span className="company-policy-item-ans-line">
                        {checkLocation(companyData?.data?.data?.['8.2']?.[0]?.['8.2.loc'])}
                        - Middle and other management: &nbsp;
                        {calculatePercentage(companyData?.data?.data?.['8.2']?.[0]?.['8.2.8'], (companyData?.data?.data?.['8.2']?.[0]?.['8.2.7'] + companyData?.data?.data?.['8.2']?.[0]?.['8.2.8']))}
                        ({t("profile.previousYear")}: {calculatePercentage(companyData?.data?.data?.['8.2']?.[0]?.['8.2.10'], (companyData?.data?.data?.['8.2']?.[0]?.['8.2.9'] + companyData?.data?.data?.['8.2']?.[0]?.['8.2.10']))}
                        , {t("profile.yearBeforePrevious")}: {calculatePercentage(companyData?.data?.data?.['8.2']?.[0]?.['8.2.12'], (companyData?.data?.data?.['8.2']?.[0]?.['8.2.11'] + companyData?.data?.data?.['8.2']?.[0]?.['8.2.12']))} )
                      </span>
                      <br/>
                      <span className="company-policy-item-ans-line">
                        {checkLocation(companyData?.data?.data?.['8.2']?.[0]?.['8.2.loc'])}
                        - Middle and other management: &nbsp;
                        {calculatePercentage(companyData?.data?.data?.['8.2']?.[0]?.['8.2.14'], (companyData?.data?.data?.['8.2']?.[0]?.['8.2.13'] + companyData?.data?.data?.['8.2']?.[0]?.['8.2.14']))}
                        ({t("profile.previousYear")}: {calculatePercentage(companyData?.data?.data?.['8.2']?.[0]?.['8.2.16'], (companyData?.data?.data?.['8.2']?.[0]?.['8.2.15'] + companyData?.data?.data?.['8.2']?.[0]?.['8.2.16']))}
                        , {t("profile.yearBeforePrevious")}: {calculatePercentage(companyData?.data?.data?.['8.2']?.[0]?.['8.2.18'], (companyData?.data?.data?.['8.2']?.[0]?.['8.2.17'] + companyData?.data?.data?.['8.2']?.[0]?.['8.2.18']))} )
                      </span>
                      <br/><br/>
                    </>
                  }
                </div>

                {gold && 
                  <div className='company-policy-item-sub-text'>
                    {t("profile.womenByFunction")} <br/><br/>
                    <span className="company-policy-item-ans-line">
                      {checkLocation(companyData?.data?.data?.['8.3']?.[0]?.['8.3.loc'])}
                      - Revenue-generating functions: &nbsp;
                      {calculatePercentage(companyData?.data?.data?.['8.3']?.[0]?.['8.3.2'], (companyData?.data?.data?.['8.3']?.[0]?.['8.3.1'] + companyData?.data?.data?.['8.3']?.[0]?.['8.3.2']))}
                    </span>
                    <br/>
                    <br/><br/>
                  </div>
                }

                {gold && 
                  <div className='company-policy-item-sub-text'>
                    {t("profile.womenTargets")} <br/><br/>
                    <span className="company-policy-item-ans-line">
                      {companyData?.data?.data?.['8.4'] == 'Yes – Please provide details below as applicable' ? 
                        "Yes \n" + companyData?.data?.data?.['8.4.a']
                        :
                        "No"
                      }
                    </span>
                    <br/><br/>
                  </div>
                }
              </div>

              <div className='company-policy-section'>
                <div className='company-policy-items'>
                  <div className='company-policy-item-text' onClick={(event) => toggleSubText(event)}>+ {t("profile.womenLeadership")}</div>
                </div>
                {gold ? 
                  <div className='company-policy-item-sub-text'>
                    {t("profile.boardWomenPercentage")} <br/><br/>
                    <span className="company-policy-item-ans-line">
                      {companyData?.data?.data?.['9.1'] == 'Yes – We track data on our Board of Directors composition' ?
                      <span>Board: {calculatePercentage(
                        (companyData?.data?.data?.['9.1.a.2'] + companyData?.data?.data?.['9.1.a.8'] + companyData?.data?.data?.['9.1.a.14'] + companyData?.data?.data?.['9.1.a.20']), 
                        (companyData?.data?.data?.['9.1.a.1'] + companyData?.data?.data?.['9.1.a.2'] + 
                         companyData?.data?.data?.['9.1.a.7'] + companyData?.data?.data?.['9.1.a.8'] + 
                         companyData?.data?.data?.['9.1.a.13'] + companyData?.data?.data?.['9.1.a.14'] + 
                         companyData?.data?.data?.['9.1.a.19'] + companyData?.data?.data?.['9.1.a.20']))} ({t("profile.previousYear")}: {calculatePercentage(
                        (companyData?.data?.data?.['9.1.a.4'] + companyData?.data?.data?.['9.1.a.10'] + companyData?.data?.data?.['9.1.a.16'] + companyData?.data?.data?.['9.1.a.22']), 
                        (companyData?.data?.data?.['9.1.a.3'] + companyData?.data?.data?.['9.1.a.4'] + 
                         companyData?.data?.data?.['9.1.a.9'] + companyData?.data?.data?.['9.1.a.10'] + 
                         companyData?.data?.data?.['9.1.a.15'] + companyData?.data?.data?.['9.1.a.16'] + 
                         companyData?.data?.data?.['9.1.a.21'] + companyData?.data?.data?.['9.1.a.22']))} {t("profile.yearBeforePrevious")}: {calculatePercentage(
                        (companyData?.data?.data?.['9.1.a.6'] + companyData?.data?.data?.['9.1.a.12'] + companyData?.data?.data?.['9.1.a.18'] + companyData?.data?.data?.['9.1.a.24']), 
                        (companyData?.data?.data?.['9.1.a.5'] + companyData?.data?.data?.['9.1.a.6'] + 
                         companyData?.data?.data?.['9.1.a.11'] + companyData?.data?.data?.['9.1.a.12'] + 
                         companyData?.data?.data?.['9.1.a.17'] + companyData?.data?.data?.['9.1.a.18'] + 
                         companyData?.data?.data?.['9.1.a.23'] + companyData?.data?.data?.['9.1.a.24']))}), comprising 
                        <br/> - Chairperson: {calculatePercentage(companyData?.data?.data?.['9.1.a.2'], (companyData?.data?.data?.['9.1.a.1'] + companyData?.data?.data?.['9.1.a.2']))}
                        <br/> - Executive Directors: {calculatePercentage(companyData?.data?.data?.['9.1.a.8'], (companyData?.data?.data?.['9.1.a.7'] + companyData?.data?.data?.['9.1.a.8']))}
                        <br/> - Non-executive directors: {calculatePercentage(companyData?.data?.data?.['9.1.a.14'], (companyData?.data?.data?.['9.1.a.13'] + companyData?.data?.data?.['9.1.a.14']))}
                        <br/> - Independent non-executive directors {calculatePercentage(companyData?.data?.data?.['9.1.a.20'], (companyData?.data?.data?.['9.1.a.19'] + companyData?.data?.data?.['9.1.a.20']))}
                      </span>
                      :
                      companyData?.data?.data?.['9.1']
                      }
                    </span>
                    <br/><br/>
                  </div>
                :
                  <div className='company-policy-item-sub-text'>
                    {t("profile.boardCsuiteWomenPercentage")} <br/><br/>
                    <span className="company-policy-item-ans-line">Board: {checkMinusOne(companyData?.data?.data?.['9.1.1'])}, C-suite: {checkMinusOne(companyData?.data?.data?.['9.1.2'])}</span>
                    <br/><br/>
                  </div>
                }
                {gold && 
                  <div className='company-policy-item-sub-text'>
                    {t("profile.csuiteWomenPercentage")} <br/><br/>
                    <span className="company-policy-item-ans-line">
                      {companyData?.data?.data?.['9.2'] == 'Yes – We track data on our C-suite composition' ?
                      <span>CEO: {calculatePercentage(companyData?.data?.data?.['9.2.a.2'], (companyData?.data?.data?.['9.2.a.1'] + companyData?.data?.data?.['9.2.a.2']))}
                        {" ("}{t("profile.previousYear")}: {calculatePercentage(companyData?.data?.data?.['9.2.a.4'], (companyData?.data?.data?.['9.2.a.3'] + companyData?.data?.data?.['9.2.a.4']))},
                        {t("profile.yearBeforePrevious")}: {calculatePercentage(companyData?.data?.data?.['9.2.a.6'], (companyData?.data?.data?.['9.2.a.5'] + companyData?.data?.data?.['9.2.a.6']))}{")"},{" "}
                        Other C-suite: {calculatePercentage(companyData?.data?.data?.['9.2.a.8'], (companyData?.data?.data?.['9.2.a.7'] + companyData?.data?.data?.['9.2.a.8']))}
                        {" ("}{t("profile.previousYear")}: {calculatePercentage(companyData?.data?.data?.['9.2.a.10'], (companyData?.data?.data?.['9.2.a.9'] + companyData?.data?.data?.['9.2.a.10']))},{" "}
                        {t("profile.yearBeforePrevious")}: {calculatePercentage(companyData?.data?.data?.['9.2.a.12'], (companyData?.data?.data?.['9.2.a.11'] + companyData?.data?.data?.['9.2.a.12']))}{")"}
                      </span>
                      :
                      companyData?.data?.data?.['9.2']
                      }
                    </span>
                    <br/><br/>
                  </div>
                }
              </div>

              <div className='company-policy-section'>
                <div className='company-policy-items'>
                  <div className='company-policy-item-text' onClick={(event) => toggleSubText(event)}>+ {t("profile.recruitmentPromotion")}</div>
                </div>
                {gold ? 
                  <>
                    <div className='company-policy-item-sub-text'>
                      {t("profile.recruitmentTrackingGold")} <br/><br/>
                      <span className="company-policy-item-ans-line">{(companyData?.data?.data?.['10.1'])}</span><br/>
                      {companyData?.data?.data?.['10.1'] == 'Yes – We track data in this regard' && 
                        <span>
                          - Total workforce: {calculatePercentage(companyData?.data?.data?.['10.1.a.2'], (companyData?.data?.data?.['10.1.a.1'] + companyData?.data?.data?.['10.1.a.2']))}
                           new hires, {calculatePercentage(companyData?.data?.data?.['10.1.a.4'], (companyData?.data?.data?.['10.1.a.3'] + companyData?.data?.data?.['10.1.a.4']))}
                           promotions <br/>
                          - Senior management (including C-suite and senior management): 
                           {calculatePercentage(companyData?.data?.data?.['10.1.a.6'], (companyData?.data?.data?.['10.1.a.5'] + companyData?.data?.data?.['10.1.a.6']))}
                           new hires, 
                           {calculatePercentage(companyData?.data?.data?.['10.1.a.8'], (companyData?.data?.data?.['10.1.a.7'] + companyData?.data?.data?.['10.1.a.8']))} promotions<br/>
                          - Middle and other management: {calculatePercentage(companyData?.data?.data?.['10.1.a.10'], (companyData?.data?.data?.['10.1.a.9'] + companyData?.data?.data?.['10.1.a.10']))}
                           new hires, {calculatePercentage(companyData?.data?.data?.['10.1.a.12'], (companyData?.data?.data?.['10.1.a.11'] + companyData?.data?.data?.['10.1.a.12']))}
                           promotions<br/>
                          - Non-managerial staff: {calculatePercentage(companyData?.data?.data?.['10.1.a.14'], (companyData?.data?.data?.['10.1.a.13'] + companyData?.data?.data?.['10.1.a.14']))}
                           new hires, {calculatePercentage(companyData?.data?.data?.['10.1.a.16'], (companyData?.data?.data?.['10.1.a.15'] + companyData?.data?.data?.['10.1.a.16']))} promotions
                        </span>
                      }
                       <span className="company-policy-item-ans-line">{(companyData?.data?.data?.['10.1.c'])}</span><br/>
                      <br/>
                    </div>
                    <div className='company-policy-item-sub-text'>
                      {t("profile.quantitativeTarget")}<br/><br/>
                      <span className="company-policy-item-ans-line">
                        {companyData?.data?.data?.['10.2']}
                        {(companyData?.data?.data?.['10.2'] == 'Yes – Yet we do not wish to provide details at this moment') && (
                          companyData?.data?.data?.['10.2.a.37'] ?? ""
                        )}
                        {(companyData?.data?.data?.['10.2'] == 'No – We believe there is no need to set such requirements or practices') && (
                          companyData?.data?.data?.['10.2.a.37'] ?? ""
                        )}
                      </span>
                      <span className="company-policy-item-ans-line">{(companyData?.data?.data?.['10.2.b'])}</span>
                      <br/><br/>
                    </div>
                    <div className='company-policy-item-sub-text'>
                      {t("profile.genderInclusive")}<br/><br/>
                      <span className="company-policy-item-ans-line">{companyData?.data?.data?.['10.3']}</span>
                      <div className="company-policy-item-ans-line">
                        {companyData?.data?.data?.['10.3.a']?.map((item, index) => (
                          <span className="company-policy-item-ans-line" key={index}>- {item}<br/></span>
                        ))}
                      </div>
                      <br/><br/>
                    </div>
                  </>
                :
                  <>
                    <div className='company-policy-item-sub-text'>
                      {t("profile.recruitmentTrackingBasic")} <br/><br/>
                      <span className="company-policy-item-ans-line">{companyData?.data?.data?.['10.1']}</span>
                      <br/><br/>
                    </div>
                    <div className='company-policy-item-sub-text'>
                      {t("profile.recruitmentTrackingLevelBasic")}<br/><br/>
                      <span className="company-policy-item-ans-line">{companyData?.data?.data?.['10.2']}</span><br/>
                      <br/><br/>
                    </div>
                    <div className='company-policy-item-sub-text'>
                      {t("profile.genderInclusive")}<br/><br/>
                      <span className="company-policy-item-ans-line">{companyData?.data?.data?.['10.3']}</span>
                      <div className="company-policy-item-ans-line">
                        {companyData?.data?.data?.['10.3.a']?.map((item, index) => (
                          <span className="company-policy-item-ans-line" key={index}>- {item}<br/></span>
                        ))}
                      </div>
                      <br/><br/>
                    </div>
                  </>
                }
              </div>
              <div className='company-policy-section'>
                <div className='company-policy-items'>
                  <div className='company-policy-item-text' onClick={(event) => toggleSubText(event)}>+ {t("profile.retention")}</div>
                </div>
                {gold ?
                  <>
                    <div className='company-policy-item-sub-text'>
                      {t("profile.retentionTracking")} <br/><br/>
                      <span className="company-policy-item-ans-line">
                        {companyData?.data?.data?.['11.1']}
                        <div>
                          {(companyData?.data?.data?.['11.1'] == 'Yes – We track such information') && 
                            <span className="company-policy-item-ans-line">
                              - Total workforce: {calculatePercentage(companyData?.data?.data?.['11.1.a.4'], (companyData?.data?.data?.['11.1.a.2'] + companyData?.data?.data?.['11.1.a.4']))} of employees who left are female <br/>
                              - Senior management (including C-suite, excluding Board members): {calculatePercentage(companyData?.data?.data?.['11.1.a.8'], (companyData?.data?.data?.['11.1.a.6'] + companyData?.data?.data?.['11.1.a.8']))} of employees who left are female <br/>
                              - Middle and other management: {calculatePercentage(companyData?.data?.data?.['11.1.a.12'], (companyData?.data?.data?.['11.1.a.10'] + companyData?.data?.data?.['11.1.a.12']))} of employees who left are female <br/>
                              - Non-managerial staff: {calculatePercentage(companyData?.data?.data?.['11.1.a.16'], (companyData?.data?.data?.['11.1.a.14'] + companyData?.data?.data?.['11.1.a.16']))} of employees who left are female" <br/>
                            </span>
                          }
                        </div>
                      </span>
                      <br/><br/>
                    </div>

                    <div className='company-policy-item-sub-text'>
                      {t("profile.retentionSenior")}<br/><br/>
                      <span className="company-policy-item-ans-line">
                        {companyData?.data?.data?.['11.2'] === 'Yes – The entity holds its senior management accountable for its gender diversity practices and performance via the following' && (
                          <>
                            {companyData?.data?.data?.['11.2']}: <br/>
                            {companyData?.data?.data?.['11.2.a'].map((item, index) => (
                              <span className="company-policy-item-ans-line" key={index}>- {item}<br/></span>
                            ))}
                          </>
                        )}
                      </span>
                      <br/><br/>
                    </div>
                  </>
                :
                  <div className='company-policy-item-sub-text'>
                    {t("profile.retentionTracking")} <br/><br/>
                    <span className="company-policy-item-ans-line">{companyData?.data?.data?.['11.1']}</span><br/>
                    <br/><br/>
                  </div>
                }
              </div>
              <div className='company-policy-section'>
                <div className='company-policy-items'>
                  <div className='company-policy-item-text' onClick={(event) => toggleSubText(event)}>+ {t("profile.training")}</div>
                </div>
                <div className='company-policy-item-sub-text'>
                  {t("profile.trainingGender")}<br/><br/>
                  <span className="company-policy-item-ans-line">
                    {companyData?.data?.data?.['12.1']} <br />
                      {companyData?.data?.data?.['12.1.a']?.map((item, index) => (
                        <span className="company-policy-item-ans-line" key={index}>- {item}</span>
                      ))}
                    {companyData?.data?.data?.['12.1.a.i']}
                  </span>
                  <br/><br/>
                </div>
                <div className='company-policy-item-sub-text'>
                  {t("profile.trainingProgram")} <br/><br/>
                  <span className="company-policy-item-ans-line">
                    {companyData?.data?.data?.['12.2']}<br/>
                    {companyData?.data?.data?.['12.2.a']?.map((item, index) => (
                      <span className="company-policy-item-ans-line" key={index}>- {item}</span>
                    ))}
                  </span>
                  {companyData?.data?.data?.['12.2.a.i']}
                  <br/><br/>
                </div>
              </div>

              <div className='company-policy-section'>
                <div className='company-policy-items'>
                  <div className='company-policy-item-text' onClick={(event) => toggleSubText(event)}>+ {t("profile.employeeEngagement")}</div>
                </div>
                <div className='company-policy-item-sub-text'>
                  {t("profile.employeeSurvey")} <br/><br/>
                  <span className="company-policy-item-ans-line">{companyData?.data?.data?.['13.1']}</span><br/>
                  {companyData?.data?.data?.['13.1.a']?.map((item, index) => (
                    <React.Fragment key={index}>
                      <span className="company-policy-item-ans-line">- {item}</span>
                    </React.Fragment>
                  ))}
                  <span className="company-policy-item-ans-line">{companyData?.data?.data?.['13.1.a.i']}</span>
                  <br/><br/>
                </div>
              </div>
            </div>

            <div className='company-policy'>
              <div className='company-policy-header'>
                <div className='company-policy-header-line1'>
                  <div className='company-policy-title'>{t("profile.externalEngagement")}</div>
                  <ExpandButton expanded={expanded} handleExpand={expandOne} />
                </div>
                <div className='company-policy-text'>{t("profile.externalEngagementDesc")}</div>
              </div>
              <div className='company-policy-section'>
                <div className='company-policy-items'>
                  <div className='company-policy-item-text' onClick={(event) => toggleSubText(event)}>+ {t("profile.supplyChain")}</div>
                  {/* <div className='company-policy-item-star'>★ Star Practice</div> */}
                </div>
                <div className='company-policy-item-sub-text'>
                  {t("profile.supplyChainPolicy")}<br/><br/>
                  <span className="company-policy-item-ans-line">{companyData?.data?.data?.['14.1']}</span><br/>
                {companyData?.data?.data?.['14.1.a']?.map((item, index) => (
                  <React.Fragment key={index}>
                    <span className="company-policy-item-ans-line">{item}</span>
                    <br />
                  </React.Fragment>
                ))}
                <span className="company-policy-item-ans-line">{companyData?.data?.data?.['14.1.a.1']}</span>
                  <br/><br/>
                </div>
              </div>

              {gold ? 
                <div className='company-policy-section'>
                  <div className='company-policy-items'>
                    <div className='company-policy-item-text' onClick={(event) => toggleSubText(event)}>
                      + {t("profile.marketingPractice")}
                    </div>
                  </div>
                  <div className='company-policy-item-sub-text'>
                    <span>{t("profile.marketingPolicy")}</span><br/><br/>
                    <span className="company-policy-item-ans-line">
                        {(companyData?.data?.data?.['15.1']) == "Yes" ? "Yes." : "No."} {companyData?.data?.data?.['15.1.a']}
                      </span>
                      <br/><br/>
                  </div>
                </div>
                :
                <></>
              }

              <div className='company-policy-section'>
                <div className='company-policy-items'>
                  <div className='company-policy-item-text' onClick={(event) => toggleSubText(event)}>+ {t("profile.communityEngagement")}</div>
                </div>
                {gold ?
                  <>
                    <div className='company-policy-item-sub-text'>
                      {t("profile.communityProgramGold")}<br/><br/>
                      <span className="company-policy-item-ans-line">
                        {(companyData?.data?.data?.['16.1'])}&nbsp;
                        {companyData?.data?.data?.['16.1.a']?.map((item, index) => (
                          <span key={index}>{item}. </span>
                        ))}
                        <br/>
                        {(companyData?.data?.data?.['16.1.a.1'])}
                      </span>
                      <br/><br/>
                    </div>
                    <div className='company-policy-item-sub-text'>
                      {t("profile.communityRecgnonition")}<br/><br/>
                      <span className="company-policy-item-ans-line">
                        {(companyData?.data?.data?.['16.2'])}.&nbsp;
                        {companyData?.data?.data?.['16.2.a']?.map((item, index) => (
                          <span key={index}>{item}. </span>
                        ))}
                        <br/>
                        {(companyData?.data?.data?.['16.2.a.1'])}
                      </span>
                      <br/><br/>
                    </div>
                  </>
                :
                  <div className='company-policy-item-sub-text'>
                    {t("profile.communityProgramBasic")}<br/><br/>
                    <span className="company-policy-item-ans-line">{companyData?.data?.data?.['15.1']}</span><br/>
                    <span className="company-policy-item-ans-line">{companyData?.data?.data?.['15.1.a']}</span>
                    <br/><span className="company-policy-item-ans-line">{companyData?.data?.data?.['15.1.a.1']}</span>
                    <br/><br/>
                  </div>
                }
              </div>
            </div>

            <div className='company-policy'>
              <div className='company-policy-header'>
                <div className='company-policy-header-line1'>
                  <div className='company-policy-title'>{t("profile.furtherInformation")}</div>
                  <ExpandButton expanded={expanded} handleExpand={expandOne} />
                </div>
                <div className='company-policy-text'></div>
              </div>
              <div className='company-policy-section'>
                <div className='company-policy-items'>
                  <div className='company-policy-item-text' onClick={(event) => toggleSubText(event)}>+ {t("profile.supplementaryInformation")}</div>
                  {/* <div className='company-policy-item-star'>★ Star Practice</div> */}
                </div>
                {gold? 
                <div className='company-policy-item-sub-text'>
                  <span className="company-policy-item-ans-line">{(companyData?.data?.data?.['17.1'][0] == 'N' && companyData?.data?.data?.['17.1'][1] == 'o') ? "N/A" : companyData?.data?.data?.['17.1']}</span><br/>
                  <span className="company-policy-item-ans-line">{companyData?.data?.data?.['17.1.a']}</span>
                  <br/><br/>
                </div> : 
                <div className='company-policy-item-sub-text'>
                  <span className="company-policy-item-ans-line">{companyData?.data?.data?.['16.1']}</span>
                  <br/><br/>
                </div>}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}
  
export default CompanyProfile;